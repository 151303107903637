// @file Store for padlet picker
import initialLibrary from '@@/bits/current_library'
import { captureNonNetworkFetchError } from '@@/bits/error_tracker'
import { ww } from '@@/bits/global'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { safeLocalStorage } from '@@/bits/safe_storage'
import { displayNameForUser } from '@@/bits/user_model'
import { fetchJson } from '@@/surface/api_fetch'

import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'

import type { WallVizEnum } from '@@/enums'
import { FetchJsonStatus, LibraryType } from '@@/enums'
import type {
  AccountKey,
  FolderId,
  JsonApiData,
  Library,
  LibraryAccess,
  LibraryId,
  Tenant,
  TenantId,
  User,
  Wall,
} from '@@/types'
import type { CollectionKey } from '@@/types/collections'

import type { PadletPickerCreateState } from '@@/vuecomposables/usePadletPickerLastCreateState'

import { uniq } from 'es-toolkit'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

// #region constants
const WALL_SEARCH_SAFE_STORAGE_KEY = 'wallSearchSuggestions'
const NATIVE_TENANT_ID = 1
const EMPTY_LIBRARY_ACCESS: LibraryAccess = {
  canViewLibraryInfo: false,
  canUpdateLibraryInfo: false,
  canSetupLti: false,
  canUpdatePermissions: false,
  canViewLibraryMemberships: false,
  canViewLibraryAnalytics: false,
  canManageMembers: false,
  canManageBilling: false,
  canTrashLibrary: false,
  canUseLibraryAsOwner: false,
  canUseLibraryAsAdmin: false,
  canUseLibraryAsTeacher: false,
  canViewLibrarySecurity: false,
  canInviteSchoolAdmins: false,
  canInviteSchoolTeachers: false,
  canInviteSchoolStudents: false,
  canInviteMembers: false,
  canViewGallery: false,
  canManageContentSafetySettings: false,
  canTransferWalls: false,
}
// #endregion

// #region types
export interface CollectionFolder {
  name: string
  id: string | FolderId
  accountKey: AccountKey
  collectionKey: CollectionKey
  walls?: WallWithCurrentUserInfo[]
  icon?: string
  logo?: string
  fallbackLogo?: string
}

export interface WallWithCurrentUserInfo extends Wall {
  currentUserIsBuilder?: boolean
  currentUserCanWrite?: boolean
}

export enum CollectionTab {
  Recents = 'combined_recents',
  Bookmarks = 'favorites',
  Libraries = 'libraries',
  Search = 'search',
}

export type PadletPickerCreateWallViz = WallVizEnum.Wall | WallVizEnum.Whiteboard
export enum PadletSelectionIntent {
  Attachment = 'attachment',
  CopyPost = 'copy_post',
  TransferPost = 'transfer_post',
}
export enum PadletPickerMode {
  Selection = 'selection', // display collection tabs and wall items in the form of cards or rows
  Creation = 'creation', // display library selector and inputs for creating a wall
}
// #endregion

export const usePadletPickerStore = defineStore('padletPicker', () => {
  // account + library states
  const globalSnackbarStore = useGlobalSnackbarStore()

  function genericFetchError(payload: { error: any; source: string }): void {
    captureNonNetworkFetchError(payload.error, { source: payload.source })
    globalSnackbarStore.genericFetchError()
  }

  const fetchAccountsStatus = ref<FetchJsonStatus>(FetchJsonStatus.NotStarted)
  const currentUser = ref<User | null>(null)
  const currentUserAccountKey = computed((): AccountKey | null =>
    currentUser.value == null ? null : { type: 'user', id: currentUser.value?.id },
  )
  const librariesById = ref<Record<LibraryId, Library>>({})
  const tenantsById = ref<Record<TenantId, Tenant>>({})
  const libraryId = ref<LibraryId | null>(null)

  // fall back for when no user is found with currentLibrary
  const defaultPersonalLibrary = computed((): Partial<Library> => {
    return {
      name: currentUser.value != null ? displayNameForUser(currentUser.value) : 'no name',
      avatar: currentUser.value?.avatar,
      libraryType: LibraryType.Solo,
      libraryAccess: EMPTY_LIBRARY_ACCESS,
      slug: currentUser.value?.username,
    }
  })
  const currentLibrary = computed((): Partial<Library> => {
    if (libraryId.value == null) return defaultPersonalLibrary.value
    return librariesById.value[libraryId.value] ?? initialLibrary
  })
  const viewableAndVisibleLibrariesArray = computed((): Library[] =>
    Object.values(librariesById.value).filter((library) => library.visible),
  )

  const isAccountsFetched = computed((): boolean => fetchAccountsStatus.value === FetchJsonStatus.Completed)
  const isAccountsFetching = computed((): boolean => fetchAccountsStatus.value === FetchJsonStatus.Fetching)
  const isNativeAccount = computed((): boolean => currentUser.value?.tenant_id === NATIVE_TENANT_ID)

  // UI states
  const currentMode = ref(PadletPickerMode.Selection)
  const lastCreateState = ref<PadletPickerCreateState | null>(null)

  const goIntoCreateMode = (createState: PadletPickerCreateState): void => {
    currentMode.value = PadletPickerMode.Creation
    lastCreateState.value = createState
  }

  const clearLastCreateState = (): void => {
    lastCreateState.value = null
  }

  /** Search Suggestions */
  const searchSuggestionsToBeSynced = ref<string[]>([])

  const searchSuggestions = computed(() =>
    uniq(searchSuggestionsToBeSynced.value.reverse().filter((q) => q != null && q.length > 0)),
  )

  const addSearchSuggestionToSafeStorage = (query: string): void => {
    const stringValue = safeLocalStorage.getItem(WALL_SEARCH_SAFE_STORAGE_KEY)
    const currentSuggestions: string[] = stringValue != null ? JSON.parse(stringValue) : []
    let newSuggestions = currentSuggestions.filter((q) => q !== query)
    newSuggestions.push(query)
    newSuggestions = newSuggestions.slice(-10)
    safeLocalStorage.setItem(WALL_SEARCH_SAFE_STORAGE_KEY, asciiSafeStringify(newSuggestions))
    searchSuggestionsToBeSynced.value = newSuggestions
  }

  function syncSearchSuggestionsWithStorage(): void {
    const stringValue = safeLocalStorage.getItem(WALL_SEARCH_SAFE_STORAGE_KEY)
    const suggestions = stringValue != null ? JSON.parse(stringValue) : []
    searchSuggestionsToBeSynced.value = suggestions
  }

  async function initialize(): Promise<void> {
    // get current user
    currentUser.value = ww.vueStartingState.user
    await fetchAccounts()
  }

  // initializes necessary account/wall info to render accounts for "Create in" header for padlet picker (tenant and library by ID arrays)
  async function fetchAccounts(): Promise<void> {
    fetchAccountsStatus.value = FetchJsonStatus.Fetching
    try {
      const response = await fetchJson(`/api/1/accounts`)
      const accountData = response.data as Array<JsonApiData<User | Library>>
      const tenantData = response.included as Array<JsonApiData<Tenant>>

      tenantData.forEach((item) => {
        if (item.type === 'tenant') {
          tenantsById.value = { ...tenantsById.value, [item.id]: item.attributes }
        }
      })

      accountData.forEach((account) => {
        if (account.type === 'library') {
          librariesById.value = { ...librariesById.value, [account.id]: account.attributes }
        }
      })

      fetchAccountsStatus.value = FetchJsonStatus.Completed
    } catch (error) {
      genericFetchError({ error, source: 'PadletPickerStoreFetchAccounts' })
      fetchAccountsStatus.value = FetchJsonStatus.Error
    }
  }

  return {
    // Account getters
    currentLibrary,
    currentUser,
    currentUserAccountKey,
    defaultPersonalLibrary,
    fetchAccountsStatus,
    isAccountsFetched,
    isAccountsFetching,
    isNativeAccount,
    librariesById,
    libraryId,
    // startingState,
    tenantsById,
    viewableAndVisibleLibrariesArray,

    // UI getters
    searchSuggestions,
    currentMode,
    lastCreateState,

    // Initialization actions
    initialize,
    // fetchStartingState,
    // Search actions
    addSearchSuggestionToSafeStorage,
    syncSearchSuggestionsWithStorage,
    // Create actions
    goIntoCreateMode,
    clearLastCreateState,
  }
})
